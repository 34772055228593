import React from "react";

import "./index.scss";

const HeroWithImage = ({ title, heroMobile, heroDesktop }) => {
  return (
    <div className="heroContainer">
      <div className="w-full h-full relative overflow-hidden">
        <h1 className="title absolute max-w-md lg:max-w-full md:text-primary">
          {title}
        </h1>
        <img src={heroMobile} alt="hero image" className="md:hidden w-full h-full" />
        <img
          src={heroDesktop}
          alt="hero image"
          className="hidden md:block w-full h-full object-none object-right-bottom md:object-cover"
        />
      </div>
    </div>
  );
};

export default HeroWithImage;
