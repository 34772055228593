import React from "react";
import { saveAs } from "file-saver";

import RRenderer from "../../../../components/richtextRenderer";
import iconArrow from "../../../../images/icon-arrow.svg";

import "./index.scss";

const onDownload = async (file) => {
  if (typeof window != "undefined") {
    const downloadResult = await fetch(file.url);
    const blob = await downloadResult.blob();
    saveAs(blob, file.fileName);
  }
  setTimeout(() => {}, 1000);
};

const About = ({ title, text, image, buttonLabel, document }) => {
  return (
    <div className="about-section-grid items-center gap-8 px-0 lg:px-16 global-x-spacing max-w-78 mx-auto py-16">
      <div>
        <h3 className="text-secondary mb-8 lg:mb-12">{title}</h3>
        <RRenderer data={text} config={{ p: "my-4" }} />
        <button
          className="flex items-center mt-8 text-secondary underline documents-underline-offset uppercase font-xs"
          onClick={() => onDownload(document.file)}
        >
          {buttonLabel}
          <img src={iconArrow} alt="Right Arrow Icon" className="ml-8 lg:ml-16" />
        </button>
      </div>
      <img
        src={image.file.url}
        className="w-full max-w-md lg:max-w-full mx-auto order-first lg:order-none"
        alt={image.title}
      />
    </div>
  );
};

export default About;
