import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";

import Layout from "../components/layout";
import HeroWithImage from "../components/heroWithImage";
import Seo from "../components/seo";
import About from "../containers/investors/esgReports/about";
import Item from "../containers/investors/esgReports/item";

import heroDesktop from "../assets/heroDesktop/Agm.png";
import heroMobile from "../assets/heroMobile/Agm.png";

const EsgReports = ({ data }) => {
  const intl = useIntl();
  return (
    <Layout inverted>
      <Seo title="ENVIRONMENTAL, SOCIAL & GOVERNANCE" />
      <HeroWithImage
        title={
          intl.locale === "fr" ? (
            <span className="inline-block max-w-screen-md">ENVIRONNEMENTAL, SOCIAL &&nbsp;&nbsp; GOUVERNANCE</span>
          ) : (
            <span className="inline-block max-w-screen-md">ENVIRONMENTAL, SOCIAL &&nbsp;&nbsp; GOVERNANCE</span>
          )
        }
        heroMobile={heroMobile}
        heroDesktop={heroDesktop}
      />
      <About
        title={data.contentfulEsgReportsPage.aboutTitle}
        text={data.contentfulEsgReportsPage.aboutText}
        image={data.contentfulEsgReportsPage.aboutImage}
        buttonLabel={data.contentfulEsgReportsPage.downloadReportButtonLabel}
        document={data.contentfulEsgReportsPage.document}
      />
      <div className="pb-24">
        <Item
          title={data.contentfulEsgReportsPage.item1Title}
          icon={data.contentfulEsgReportsPage.item1Icon}
          text={data.contentfulEsgReportsPage.item1Text}
        />
        <Item
          title={data.contentfulEsgReportsPage.item2Title}
          icon={data.contentfulEsgReportsPage.item2Icon}
          text={data.contentfulEsgReportsPage.item2Text}
          isOdd
        />
        <Item
          title={data.contentfulEsgReportsPage.item3Title}
          icon={data.contentfulEsgReportsPage.item3Icon}
          text={data.contentfulEsgReportsPage.item3Text}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query EsgReports($locale: String) {
    contentfulEsgReportsPage(node_locale: { eq: $locale }) {
      aboutTitle
      aboutText {
        raw
      }
      aboutImage {
        file {
          url
        }
        title
      }
      downloadReportButtonLabel
      document {
        file {
          url
          fileName
        }
      }
      item1Title
      item1Icon {
        file {
          url
        }
        title
      }
      item1Text {
        raw
      }
      item2Title
      item2Icon {
        file {
          url
        }
        title
      }
      item2Text {
        raw
      }
      item3Title
      item3Icon {
        file {
          url
        }
        title
      }
      item3Text {
        raw
      }
    }
  }
`;

export default EsgReports;
