import React from "react";

import RRenderer from "../../../../components/richtextRenderer";

import "./index.scss";

const Item = ({ title, icon, text, isOdd }) => {
  return (
    <div className="report-item-grid global-x-spacing max-w-78 mx-auto py-3 px-0 lg:px-16">
      <div className="flex flex-col items-center justify-center h-full bg-dark-blue p-8">
        <img src={icon.file.url} alt={icon.title} className="report-item-icon" />
        <div className="mt-6 font-2xl text-white">{title}</div>
      </div>
      {isOdd ? (
        <div className="p-8 md:py-10 md:px-12 bg-primary">
          <RRenderer data={text} config={{ h5: "font-2xl text-dark-blue py-2" }} />
        </div>
      ) : (
        <div className="p-8 md:py-10 md:px-12 bg-secondary">
          <RRenderer data={text} config={{ h5: "font-2xl text-dark-blue py-2" }} />
        </div>
      )}
    </div>
  );
};

export default Item;
